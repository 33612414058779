<template>
  <div>
    <div
      class="uk-header uk-background-secondary uk-position-relative"
      uk-height-viewport="min-height: 400; offset-bottom: 40"
      style="background-image: url('http://cdn.hrvstr.club/wp-content/uploads/2020/11/15125220/header-split-left.jpg'); 
      background-size: cover; background-position: center; background-repeat: no-repeat;"
    >
      <div class="uk-position-small uk-position-center uk-text-center">
        <div class="container uk-container-small">
          <img src="./assets/Harvester-site-logosmall2.png" alt="" height="70" style="height:70px;"/>
          <h2 class="uk-light uk-heading-small">
            Connecting, honoring, and celebrating <br class="uk-visible@m">
            those that fish, hunt, and shoot.
          </h2>
          
        </div>
      </div>
    </div>
    <div class="uk-intro uk-padding uk-text-center">
      <div class="uk-container uk-container-small">
        <h3 class="orange">Website Under Maintenance Operations</h3>
        <p>
          Please check back soon.
        </p>
      </div>
    </div>
   
    
    <div class="uk-footer uk-background-secondary uk-padding">
      <div class="uk-container uk-container-small uk-text-center">
        <img class="logo-footer" src="./assets/Harvester-site-logosmall2.png" alt="" uk-img/>
        <p>
          &copy; 2025 Harvester Club. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from './components/ContactForm.vue'

export default {
  name: "App",
  components: {
    ContactForm
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Quincy CF";
  src: url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.eot");
  src: url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/8ca8f6527c36fecba5d7d807482b61f2.svg#Quincy CF") format("svg");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,700&display=swap');

html {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  color: #1d1d1d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.uk-button-primary {
  background-color: #a76032;
  border-color: #a76032;
}
h2.uk-heading-small{
  font-family: "Quincy CF";
  color:#fff;
  font-size: 2.3rem;
}
h3.orange{
  font-family: "Quincy CF";
  color: #a76032;
  font-size: 2rem;
}
.benefits{
  background-image: url('https://cdn.hrvstr.club/wp-content/uploads/2021/11/30222937/landing-benefits.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color:#fff;
}
.benefits h4{
  font-family: "Quincy CF";
  color:#fff;
  font-size: 1.8rem;
  margin: 10px 0;
}
.benefits h4 img{
  width: 30px;
  margin-right: 10px;
}
.benefits p{
  margin: 0;
}
.sign-up{
  background-image: url('https://cdn.hrvstr.club/wp-content/uploads/2021/11/30223954/landing-signup.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}
.uk-footer{
  padding: 70px 0px;
  background-image: url('./assets/bg-footer-optimized.jpg');
}
.uk-footer .logo-footer{
  height: 40px;
  width: auto;
}
</style>