<template>
  <div class="uk-width-1-2@s container">
    <div v-if="!complete">
      <div v-if="error" class="uk-alert-danger" uk-alert>
        {{ error }}
      </div>
      <form @submit.prevent="sendEmail" v-if="!loading">
        <label>First Name</label>
        <input
          type="text"
          v-model="name"
          name="name"
          id="name"
          placeholder="First Name"
          required
        />
        <label>Last Name</label>
        <input
          type="text"
          v-model="lastName"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          required
        />
        <label>Email</label>
        <input
          type="email"
          v-model="email"
          name="email"
          placeholder="Your Email"
          required
        />
        <label>Home City</label>
        <input
          type="text"
          v-model="city"
          name="city"
          id="city"
          placeholder="Your City"
          required
        />

        <vue-recaptcha
          siteKey="6LcrS28dAAAAAGJzyl7531-C76ZkTUPCmjmwm6RM"
          size="normal"
          theme="light"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>

        <div class="uk-margin">
          <button
            type="submit"
            value="Sign"
            class="uk-button uk-button-primary uk-border-rounded"
          >
            Sign Up
          </button>
        </div>
      </form>
      <p v-if="loading">Loading</p>
    </div>
    <div v-else>
      <p>Thank you for your interest!</p>
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "ContactUs",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      loading: false,
      complete: false,
      name: "",
      lastName: "",
      email: "",
      city: "",
      recaptcha: "",
      error: "",
    };
  },
  methods: {
    sendEmail(e) {
      this.loading = true;

      const emailsJSParams = {
        name: this.name,
        email: this.email,
        lastName: this.lastName,
        city: this.city,
        'g-recaptcha-response': this.recaptcha,
      };

      try {
        emailjs
          .send(
            'service_4qivazl',
            'template_6lzvina',
            emailsJSParams,
            'user_T1Obw6mXZOwFFfsi6gFnJ'
          )
          .then(
            (res) => {
              this.loading = false;
              this.complete = true;
              console.log("SUCCESS!", res.status, res.text);
              // Reset form field
              this.name = "";
              this.lastName = "";
              this.email = "";
              this.city = "";
              this.vueRecaptcha = "";
            },
            (err) => {
              this.loading = false;
              this.complete = false;
              console.log("FAILED...", err);
              this.error = "Complete all fields and verify the captcha";
            }
          );
      } catch (error) {
        console.log(error);
      }
    },
    recaptchaVerified(response) {
      console.log("recaptchaVerified", response);
      return (this.recaptcha = response);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.container {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 20px;
}

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.uk-button-primary {
  background-color: #a76032;
  border-color: #a76032;
}
</style>
